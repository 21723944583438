import type { NextPage } from 'next';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Heading, Input, Logo, notify, Button, Text } from '@corellium/metal';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import useUser from 'hooks/useUser';
import { handleError } from 'utils/handleError';
import { apiHostFromURL, ERRORS_IDS } from 'utils/http';

type LoginInput = {
  username: string;
  password: string;
  newDomain: string;
};

const loginSchema = yup.object({
  username: yup.string().trim().required('Username is required'),
  password: yup.string().required('Password is required'),
  newDomain: yup.string().trim().required('Domain is required'),
});

const Login: NextPage = () => {
  const { push, isReady, query } = useRouter();
  const { user, login, queryAuth } = useUser();
  const [loading, setLoading] = useState(false);
  const [hasAttemptedQueryAuth, setHasAttemptedQueryAuth] = useState(false);
  const promptDomain = process.env.NEXT_PUBLIC_PROMPT_DOMAIN === 'true';
  const defaultDomain = promptDomain ? '' : apiHostFromURL();

  const {
    register,
    formState: { errors },
    handleSubmit,
    getFieldState,
    setValue,
  } = useForm<LoginInput>({
    mode: 'onBlur',
    defaultValues: {
      username: '',
      password: '',
      newDomain: defaultDomain,
    },
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    if (isReady && query.domain) {
      setValue('newDomain', query.domain as string);
      if (query.token && !hasAttemptedQueryAuth) {
        setLoading(true);
        setHasAttemptedQueryAuth(true);
        queryAuth(query.token as string, query.domain as string)
          .then(() => {
            push('/files').catch(handleError);
          })
          .catch(handleError);
      }
    }
  }, [
    hasAttemptedQueryAuth,
    isReady,
    push,
    query.domain,
    query.token,
    queryAuth,
    setValue,
  ]);

  useEffect(() => {
    if (!user?.token) {
      setLoading(false);
      return;
    }

    setLoading(true);
    push('/files').catch(handleError);
  }, [push, user?.token]);

  const handleLogin: SubmitHandler<LoginInput> = async ({
    newDomain,
    password,
    username,
  }) => {
    setLoading(true);

    try {
      await login(username, password, newDomain);
    } catch (error) {
      const description =
        error instanceof Error ? error.message : 'Error logging in';
      setLoading(false);
      const href = newDomain.startsWith('https')
        ? newDomain
        : `https://${newDomain}`;

      if (description === ERRORS_IDS.NETWORK_ERROR) {
        notify({
          variant: 'error',
          title: 'Error',
          description: `Failed to connect to server. This may be due to an invalid certificate. Try going to ${newDomain} directly and proceed unsafe.`,
          actions: (
            <Button size="sm" variant="secondary" asChild>
              <a href={href} target="_blank" rel="noreferrer">
                Open {newDomain}
              </a>
            </Button>
          ),
        });
        return;
      }

      notify({ variant: 'error', title: 'Error', description });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex w-full max-w-[21rem] flex-col gap-8 text-center">
        <div className="flex flex-col items-center gap-6">
          <Logo type="symbol" />
          <div className="flex flex-col gap-4">
            <Heading size="sm">On-Site Dashboard</Heading>
            <Text size="sm" className="text-foreground/80">
              Welcome back! Please enter your username, password and domain to
              continue.
            </Text>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(handleLogin)}
          className="flex flex-col gap-4 text-left"
        >
          <Input.Text
            id="username"
            autoComplete="username"
            label="Username"
            onChangeText={(val) => setValue('username', val)}
            placeholder="Username"
            isInvalid={Boolean(getFieldState('username').error)}
            hint={errors.username?.message}
            {...register('username')}
          />
          <Input.Text
            id="password"
            type="password"
            autoComplete="current-password"
            label="Password"
            placeholder="Password"
            isInvalid={Boolean(getFieldState('password').error)}
            hint={errors.password?.message}
            onChangeText={(val) => setValue('password', val)}
            {...register('password')}
          />
          {promptDomain && (
            <Input.Text
              id="newDomain"
              label="Domain"
              onChangeText={(val) => setValue('newDomain', val)}
              placeholder="acme-files.enterprise.corellium.com"
              isInvalid={Boolean(getFieldState('newDomain').error)}
              hint={errors.newDomain?.message}
              {...register('newDomain')}
            />
          )}
          <Button
            type="submit"
            size="lg"
            isLoading={loading}
            disabled={loading}
            isFullWidth
          >
            {loading ? 'Loading' : 'Login'}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
